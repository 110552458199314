<template>
  <div class="content">
        <button v-if="isCart" class="allclearbn" @click="clearAllCart">カートの内容を削除する</button>


<plugin_cartFlow flow="5" />

<div class="steps">
  <div class="steptitle">ステップ5：お届け先情報の入力</div>
  <div class="stepcontent" v-if="loaded">



<div v-for="i in unit" :key="i" :id="'item'+i" :ref="'item'+i">
<p class="order_unittitle" v-if="unit!=1">{{i}}枚目の内容を選択</p>


<p class="order_unitdetail" v-if="unit>1">
  {{i}}枚目<!--【{{tagged_values[i-1].letter_type}}】-->【{{tagged_values[i-1].letter_callname}}】
【{{tagged_values[i-1].letter_target}}】
【{{tagged_values[i-1].letter_lang}}】
の内容をご記入ください。</p>

<p class="order_unitdetail" v-else>
内容をご記入ください。</p>




    <table
      width="100%"
      border="0"
      cellpadding="0"
      cellspacing="1"
      class="formBox"
    >
      <tbody>


        <tr>
          <th class="formListTh" colspan="2">
            お届け先の情報 （お手紙をどなたにお届けしますか？）
          </th>
        </tr>

        <tr>
                  <td width="25%" class="tdmenu">
                    住所の読み込み
                  </td>
          <td class="" colspan="2">
           <!--<button @click="copyaddr(i-1,'sender')">ご依頼主様の住所を読み込む</button>-->


           <div>
              <label><input type="radio" :name="'mode'+i" value="create" v-model="record.fields.mydeliver.subfields[i-1].addressmode.value" @click="address_select_before" @change="copyaddr(i-1,'empty')"> 新しいお届け先を入力する</label>
</div>
           <div>
              <label><input type="radio" :name="'mode'+i" value="sender" v-model="record.fields.mydeliver.subfields[i-1].addressmode.value" @click="address_select_before" @change="copyaddr(i-1,'sender')"> ご依頼主様の住所を読み込む</label>
</div>
              <div v-if="myaddress.length">
              <label><input type="radio" :name="'mode'+i" value="update" v-model="record.fields.mydeliver.subfields[i-1].addressmode.value" @click="address_select_before" @change="copyaddr(i-1,'myaddress')"> 登録したお届け先を読み込む</label> :
              <select @change="copyaddr(i-1,'myaddress')" v-model="record.fields.mydeliver.subfields[i-1].id.value">
                <option v-for="adr in myaddress" :value="adr.ID" :key="adr.ID">{{adr.fields.title.value}}</option>
              </select>

              
              </div>
<!---->


          </td>
        </tr>

        
        <tr v-if="record.fields.mydeliver.subfields[i-1].addressmode.value=='update'">
          <td class="formListTh3" >
            お届け先の編集
          </td>
          <td class="formListTdw">
            
      [ <router-link @click.native="scrollToTop" :to="{ path: '/mypage/deliver', query: { rd: 'back',id:record.fields.mydeliver.subfields[i-1].id.value }}">このお届け先を編集する</router-link> ]
            
          </td>
        </tr>

        <tr>
          <td class="formListTh3">お届け先名<span class="nesry">必須</span></td>
          <td class="formListTdw">
            <div v-if="record.fields.mydeliver.subfields[i-1].addressmode.value=='update'">
              {{record.fields.mydeliver.subfields[i-1].sei.value}}
              {{record.fields.mydeliver.subfields[i-1].mei.value}} 様
              </div><div v-else>
            <span class="em2">姓</span
            ><input class="fm_100" name="deliver_name1" type="text" 
            v-model="record.fields.mydeliver.subfields[i-1].sei.value"
              />
            <span class="em2">名</span
            ><input class="fm_100" name="deliver_name2" type="text" 
            v-model="record.fields.mydeliver.subfields[i-1].mei.value" />
            様

            <inlineError :name="'mydeliver-sei-'+i" :errors="errors_global" />
            <inlineError :name="'mydeliver-mei-'+i" :errors="errors_global" />

            <div class="cartinfosample">
              例：<span>黒須</span> ／ <span>一郎</span>
            </div></div>
          </td>
        </tr>
        <tr>
          <td class="formListTh3">
            お届け先名ふりがな<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">
            
            <div v-if="record.fields.mydeliver.subfields[i-1].addressmode.value=='update'">
              {{record.fields.mydeliver.subfields[i-1].sei_kana.value}}
              {{record.fields.mydeliver.subfields[i-1].mei_kana.value}} さま
              </div><div v-else>
            <div class="cartselinfo">
              ※ひらがな、またはカタカナで入力してください。
            </div>
            <span class="em2">せい</span
            ><input
              class="fm_100"
              name="deliver_name_kana1"
              type="text"
            v-model="record.fields.mydeliver.subfields[i-1].sei_kana.value"
            />
            <span class="em2">めい</span
            ><input
              class="fm_100"
              name="deliver_name_kana2"
              type="text"
            v-model="record.fields.mydeliver.subfields[i-1].mei_kana.value"
            />
            さま

            <inlineError :name="'mydeliver-sei_kana-'+i" :errors="errors_global" />
            <inlineError :name="'mydeliver-mei_kana-'+i" :errors="errors_global" />

            <div class="cartinfosample">
              例：<span>くろす</span> ／ <span>いちろう</span>
            </div></div>
              
          </td>
        </tr>
        <tr>
          <td class="formListTh3">お届け先住所</td>
          <td class="formListTdw">
            
            <div v-if="record.fields.mydeliver.subfields[i-1].addressmode.value=='update'">
              〒{{record.fields.mydeliver.subfields[i-1].zip.value}}<br>
              {{record.fields.mydeliver.subfields[i-1].state.value}}
              {{record.fields.mydeliver.subfields[i-1].city.value}}
              {{record.fields.mydeliver.subfields[i-1].street.value}}<br>
              {{record.fields.mydeliver.subfields[i-1].building.value}}
              {{record.fields.mydeliver.subfields[i-1].other.value}}
              </div><div v-else>
              
            <div class="cartinfosample">
              ※ご住所は必ず日本語でご入力ください。英語表記は登録することができません。（建物名を除く）
            </div>
            <table border="0" class="formBox">
              <tbody>
                <tr>
                  <td width="25%" class="tdmenu">
                    郵便番号<span class="nesry">必須</span>
                  </td>
                  <td width="75%" class="formListTdw">
                    <div class="cartselinfo">
                      ※住所が未入力の場合、正しく郵便番号を入力すると自動的に住所を入力します。
                    </div>
                    <input
                      class="fm_150 zipsearch"
                      name="deliver_zip"
                      type="text"
            v-model="record.fields.mydeliver.subfields[i-1].zip.value"
                    @change="zipcode_get(i)"
                    />

            <inlineError :name="'mydeliver-zip-'+i" :errors="errors_global" />
                    <div class="cartinfosample">例：<span>123-0001</span></div>
                  </td>
                </tr>
                <tr>
                  <td class="tdmenu">
                    都道府県<span class="nesry">必須</span>
                  </td>
                  <td class="formListTdw">
                    <select @change="update_deliver"
                      name="deliver_ken"
            v-model="record.fields.mydeliver.subfields[i-1].state.value"
                      class="zipsearch_ken"
                    >
                      <option value="">都道府県</option>
                      <option value="北海道">北海道</option>
                      <option value="青森県">青森県</option>
                      <option value="岩手県">岩手県</option>
                      <option value="宮城県">宮城県</option>
                      <option value="秋田県">秋田県</option>
                      <option value="山形県">山形県</option>
                      <option value="福島県">福島県</option>
                      <option value="茨城県">茨城県</option>
                      <option value="栃木県">栃木県</option>
                      <option value="群馬県">群馬県</option>
                      <option value="埼玉県">埼玉県</option>
                      <option value="千葉県">千葉県</option>
                      <option value="東京都">東京都</option>
                      <option value="神奈川県">神奈川県</option>
                      <option value="新潟県">新潟県</option>
                      <option value="富山県">富山県</option>
                      <option value="石川県">石川県</option>
                      <option value="福井県">福井県</option>
                      <option value="山梨県">山梨県</option>
                      <option value="長野県">長野県</option>
                      <option value="岐阜県">岐阜県</option>
                      <option value="静岡県">静岡県</option>
                      <option value="愛知県">愛知県</option>
                      <option value="三重県">三重県</option>
                      <option value="滋賀県">滋賀県</option>
                      <option value="京都府">京都府</option>
                      <option value="大阪府">大阪府</option>
                      <option value="兵庫県">兵庫県</option>
                      <option value="奈良県">奈良県</option>
                      <option value="和歌山県">和歌山県</option>
                      <option value="鳥取県">鳥取県</option>
                      <option value="島根県">島根県</option>
                      <option value="岡山県">岡山県</option>
                      <option value="広島県">広島県</option>
                      <option value="山口県">山口県</option>
                      <option value="徳島県">徳島県</option>
                      <option value="香川県">香川県</option>
                      <option value="愛媛県">愛媛県</option>
                      <option value="高知県">高知県</option>
                      <option value="福岡県">福岡県</option>
                      <option value="佐賀県">佐賀県</option>
                      <option value="長崎県">長崎県</option>
                      <option value="熊本県">熊本県</option>
                      <option value="大分県">大分県</option>
                      <option value="宮崎県">宮崎県</option>
                      <option value="鹿児島県">鹿児島県</option>
                      <option value="沖縄県">沖縄県</option>
                    </select>


            <inlineError :name="'mydeliver-state-'+i" :errors="errors_global" />

                    <div class="cartinfosample">例：<span>栃木県</span></div>
                  </td>
                </tr>
                <tr>
                  <td class="tdmenu">
                    市区町村<span class="nesry">必須</span>
                  </td>
                  <td class="formListTdw">
                    <input
                      class="fm_150 zipsearch_shi"
                      name="deliver_shi"
                      type="text"
            v-model="record.fields.mydeliver.subfields[i-1].city.value"
                    />

            <inlineError :name="'mydeliver-city-'+i" :errors="errors_global" />

                    <div class="cartinfosample">
                      例：<span>那須郡那須町</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tdmenu">
                    地域・番地<span class="nesry">必須</span>
                  </td>
                  <td class="formListTdw">
                    <input
                      class="fm_250 zipsearch_machi"
                      name="deliver_addr01"
                      type="text"
            v-model="record.fields.mydeliver.subfields[i-1].street.value"
                    />

            <inlineError :name="'mydeliver-street-'+i" :errors="errors_global" />

                    <div class="cartinfosample">
                      例：<span>高久乙3584-4</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tdmenu2">アパート・マンション・建物名</td>
                  <td class="formListTdw">
                    <div class="cartselinfo">
                      ※建物名、号室まで正確に入力してください
                    </div>
                    <input
                      class="fm_250"
                      name="deliver_addr02"
                      type="text"
            v-model="record.fields.mydeliver.subfields[i-1].building.value"
                    />
            <inlineError :name="'mydeliver-building-'+i" :errors="errors_global" />

                    <div class="cartinfosample">
                      例：<span>クリスマスマンションＡ棟５００号室</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="tdmenu2">様方・会社名</td>
                  <td class="formListTdw">
                    <input
                      class="fm_250"
                      name="deliver_addr03"
                      type="text"
            v-model="record.fields.mydeliver.subfields[i-1].other.value"
                    />
            <inlineError :name="'mydeliver-other-'+i" :errors="errors_global" />

                    <div class="cartinfosample">例：<span>黒須様方</span></div>
                  </td>
                </tr>
              </tbody>
            </table>

</div>

          </td>
        </tr>
        <tr>
          <td class="formListTh3">
            お届け先電話番号<span class="nesry">必須</span>
          </td>
          <td class="formListTdw">

            
            <div v-if="record.fields.mydeliver.subfields[i-1].addressmode.value=='update'">
              {{record.fields.mydeliver.subfields[i-1].tel.value}}
              </div><div v-else>

            <div class="cartselinfo">
              ※数字とハイフンで入力してください。<br />※市外局番から入力してください
            </div>

            <input
              class="fm_150"
              name="deliver_tel"
              type="text"
            v-model="record.fields.mydeliver.subfields[i-1].tel.value"
            />
            <inlineError :name="'mydeliver-tel-'+i" :errors="errors_global" />

            <div class="cartinfosample">例：<span>0287-99-9999</span></div>
            
            </div>
          </td>
        </tr>

        <tr v-if="record.fields.join.value && record.fields.mydeliver.subfields[i-1].addressmode.value!='update'">
          <td class="formListTh3">
            お届け先の保存
          </td>
          <td class="formListTdw">

            <label><input
              class="fm_150"
              name="deliver_savedeliver"
              type="checkbox"
            v-model="record.fields.mydeliver.subfields[i-1].savedeliver.value"
            /> このお届け先を保存する</label>
            <br>
            <input
              class="fm_150"
              name="deliver_title"
              type="text"
            v-model="record.fields.mydeliver.subfields[i-1].title.value"
            />
            <inlineError :name="'mydeliver-title-'+i" :errors="errors_global" />

            <div class="cartinfosample">このお届け先を保存し、次回以降も簡単に呼び出すことができます。</div>
          </td>
        </tr>


        <tr>
          <th class="formListTh" colspan="2">
            配送方法
          </th>
        </tr>

        <tr>
          <td class="formListTh3">配送方法<span class="nesry">必須</span></td>
          <td class="formListTdw">

<div class="delivers" v-for="deliver,d in computed.delivers[i-1].delivermethods" :key="d">
<label v-if="deliver.status">
            <input class="zoomcheck" :name="'deliver_method'+i" type="radio" 
           
            v-model="record.fields.mydeliver.subfields[i-1].type.value"
            :value="deliver.ID"
            
              />
              
              
  {{deliver.label}}
  ({{deliver.area}} /
  {{deliver.size}}
  ：{{deliver.cost}}円)</label>
  
  <div class="description" v-html="deliver.description" v-if="deliver.status && deliver.description"></div>

              </div>
            <inlineError :name="'mydeliver-type-'+i" :errors="errors_global" />

<div class="deliverinfo">
  <div class="img">
    <p>【こんなお客様には宅急便による配送がおすすめ！】</p>
    
    <img src="../../assets/images/deliver1.png" alt=""></div>

  <div class="info" v-if="0">こちらのお荷物は＋〇〇〇円で宅急便による日時指定配達が可能です。<br>
  
  <router-link @click.native="scrollToTop" class="bn_back" to="/">＞地域別送料一覧表はこちらから</router-link>

</div></div>
          </td>
        </tr>

        
        <tr>
          <th class="formListTh" colspan="2">
            到着日時指定
          </th>
        </tr>

        <tr>
          <td class="formListTh3">配送日時指定</td>
          <td class="formListTdw">

<div class="delivers">

<!--
<p>
             <label><input class="zoomcheck" :name="'deliver_option1'+i" type="radio"   /> 配達日時を指定しない</label></p>
             <p>
             <label><input class="zoomcheck" :name="'deliver_option1'+i" type="radio"   /> 配達日時を指定する</label></p>
<br>
-->
<div v-if="!selectedDeliver(i-1)">

  配送日時を選択するには、まず配送方法を選択してください。
</div>
<div v-else>
<div class="arrive_selector" v-if="selectedDeliver(i-1).allowed_days.length">
お届け希望日 
<select name="" id="" v-model="record.fields.mydeliver.subfields[i-1].date.value">
  <option value="">指定しない</option>
  <option :value="d" v-for="d,di in selectedDeliver(i-1).allowed_days" :key="di">{{fetchCurrentDate("YYYY年M月D日", d)}}</option>
</select>
<br>
</div>
<div class="arrive_selector" v-else>
(ご選択いただいた配送方法は、お届け希望日の指定に対応していません。)
</div>


<div class="arrive_selector" v-if="selectedDeliver(i-1).allowed_times.length">
時間帯のご指定
<select name="" id="" v-model="record.fields.mydeliver.subfields[i-1].time.value">
  <option value="">指定しない</option>
  <option :value="d2" v-for="d2,di2 in selectedDeliver(i-1).allowed_times" :key="di2"> {{d2}}</option>
</select>
<br>
</div>
<div class="arrive_selector" v-else>
(ご選択いただいた配送方法は、お届け時間帯の指定に対応していません。)
</div>
</div>

  <div class="description" v-html="selectedDeliver(i-1).description_arrive" v-if="selectedDeliver(i-1).status && selectedDeliver(i-1).description_arrive"></div>
</div>
          </td>
        </tr>


      </tbody>
    </table>
</div>
</div>
</div>


<div class="footbns">
<a class="bn_back" @click.prevent="back">前へもどる</a>
<a class="bn_next"  @click.prevent="submit">次へ進む</a>
</div>


    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
    />

<fullloader :loading="fullloading" />

  </div>
</template>

<script>
import inlineError from "@/components/inlinError.vue";
export default {
  components: { inlineError},
  data:function(){
    return {
      values:[],
      tagged_values:[],
      myaddress:{},
    }
  },
  created:function(){
    console.log("e-----------------")
    
      for(var f in this.record.fields.mydeliver.subfields){
        console.log("this.record.fields.mydeliver.subfields[f]",this.record.fields.mydeliver.subfields[f])
        if(typeof this.record.fields.mydeliver.subfields[f].savedeliver=="undefined"){
          this.$set(this.record.fields.mydeliver.subfields[f],"savedeliver",{"value":true});
        }
        if(typeof this.record.fields.mydeliver.subfields[f].title=="undefined"){
          this.$set(this.record.fields.mydeliver.subfields[f],"title",{"value":true});
        }
        if(typeof this.record.fields.mydeliver.subfields[f].addressmode=="undefined"){
          this.$set(this.record.fields.mydeliver.subfields[f],"addressmode",{"value":true});
        }
        if(typeof this.record.fields.mydeliver.subfields[f].time=="undefined"){
          this.$set(this.record.fields.mydeliver.subfields[f],"time",{"value":""});
        }
        if(typeof this.record.fields.mydeliver.subfields[f].date=="undefined"){
          this.$set(this.record.fields.mydeliver.subfields[f],"date",{"value":""});
        }
      }


    console.log("e2-----------------")

      this.fullloading=true;
    this.$parent.middle=2;
      this.loaded_countmax=1;
      //まず商品定義を読み込む
      //alert(this.$route.params.label)
      this.get_itemoption(this.$route.params.label).then(()=>{
        this.values=this.record.fields.items.subfields;
        var tagged_values=[];
        for(var i=0;i<this.values.length;i++){console.log("this.get_option_value(i)",this.get_option_value(i))
          tagged_values.push(this.get_tagged_values(this.get_option_value(i)));
        }
        this.tagged_values=tagged_values;
        
        //this.loaded=true;
        this.get_totalamount().then((data)=>{
          console.log(data);
          this.computed=data;
          this.loaded_countup();
          this.hashScroll();

      //アドレス
      this.chg_reset();
      if(this.logined&&!this.myDataLoaded){
        this.bn_getMyData();
      }

        });
        

      });
      


  },
    computed:{
      //...mapState(["options"]),
      label:function(){
        return this.$route.params.label;
      },
    ID:function(){
      return this.$route.params.ID;
    }
    },
  methods:{

    
    address_select_before:function(e){
      //console.log("v",e.target.value)
      //if(this.address_idx==index)return true;
      if(this.changedflg && !window.confirm("編集中の内容が失われますが切り替えますか？")){
        e.preventDefault();
        return false;
      }
    },
    /*
    address_select:function(idx){
      if(this.mode=="update"){
        this.chg_address(idx);
      }else{
        console.log('to empty');
        this.chg_reset();
      }
      
    },*/
    chg_reset:function(){
      this.$nextTick(()=>{
        this.changedflg=false;console.log('リセット');
      })
    },
    myaddress_byID:function(ID){
      //let ID = this.record.fields.mydeliver.subfields[i-1].id.value
      for(var i=0;i<this.myaddress.length;i++){
        if(ID==this.myaddress[i].ID)return this.myaddress[i];
      }
      return this.myaddress[0];
    },
/*
    chg_address:function(i){
      if(this.changedflg && !window.confirm("編集中の内容が失われますが切り替えますか？")){
        this.record.fields.mydeliver.subfields[i].id.value=this.address_idx_prev;
        return false;
      }
      this.address_idx_prev=this.record.fields.mydeliver.subfields[i].id.value;
      this.mode="update"
     console.log('chg_address:');
      // this.$set(this.address_idx)
      if(!isNaN(this.address_idx)){
        //this.$set(this.tmp_record)
        console.log('this.myaddress[this.address_idx]',this.myaddress[this.address_idx]);
        this.tmp_record=this.tmp_record=JSON.parse(JSON.stringify(this.myaddress_byID(this.record.fields.mydeliver.subfields[i].id.value)));;
      }

      //console.log(this.address_idx);
      this.chg_reset();
    },*/

    bn_getMyData: function () {
      this.getMyData().then((data) => {
        console.log("data got!", data.mydata, this.record);
        this.myaddress=data.myaddress;
        this.myDataLoaded = true;

        if(this.myaddress.length)for(var i=0;i<this.record.fields.mydeliver.subfields.length;i++){
          if(!this.record.fields.mydeliver.subfields[i].id.value){
            this.record.fields.mydeliver.subfields[i].id.value=this.myaddress[0].ID;
          }

          //読み込み時点で登録したお届け先を読み込む設定になっていた場合、再度読み込みなおす。
          if(this.record.fields.mydeliver.subfields[i].addressmode.value=="update"){
            this.copyaddr(i,'myaddress',true);
          }

          
          console.log("this.record.fields.mydeliver.subfields[i]",this.record.fields.mydeliver.subfields[i]);
        }

        this.$nextTick(() => {
          //$('.formBox.fixed').find("input").attr('readonly',true);
        });
      });
    },

    //都道府県が変更されたタイミングで、計算された送料を読み込みなおす
    update_deliver:function(){
      console.log("update_deliverupdate_deliverupdate_deliverupdate_deliver")
      this.get_totalamount().then((data)=>{
          console.log(data);
          this.computed=data;
      });
    },

    selectedDeliver:function(index){
      for(var i=0;i<this.computed.delivers[index].delivermethods.length;i++){
        if(
          this.computed.delivers[index].delivermethods[i].ID
          ==
          this.record.fields.mydeliver.subfields[index].type.value
          ){
            return this.computed.delivers[index].delivermethods[i];
        }
      }
      return false;
    },
    copyaddr:function(index,mode,silent){
      var prev_state=this.record.fields.mydeliver.subfields[index].state.value;
      if(mode=="sender"){
        //注文者情報の読み込み
        console.log(this.record.fields.mydeliver.subfields[index]);
        for(var f in this.record.fields.mydeliver.subfields[index]){
          if(typeof this.record.fields[f]!="undefined"){
            if(["sei","mei","sei_kana","mei_kana"].indexOf(f)!==-1)continue;
            this.$set(this.record.fields.mydeliver.subfields[index][f],"value",this.record.fields[f].value);
            
          }
        }
        if(!silent){
          this.error_title= "住所情報を読み込みました。";
          this.error_info = "ご注文者(ご依頼主)様の住所情報を読み込み、住所欄に自動入力しました。";
          this.visible_error = true;
        }

      }else if(mode=="empty"){
        //注文者情報の読み込み
        for(var f in this.record.fields.mydeliver.subfields[index]){
            if(["savedeliver","addressmode","id"].indexOf(f)!==-1)continue;
            this.$set(this.record.fields.mydeliver.subfields[index][f],"value","");
        }
        if(!silent){
          this.error_title= "住所情報を消去しました。";
          this.error_info = "住所情報を新規登録するため内容を消去しました。";
          this.visible_error = true;
        }

      }else if(mode=="myaddress"){
        let myaddress=this.myaddress_byID(this.record.fields.mydeliver.subfields[index].id.value);
        //console.log("myaddress!!",myaddress.fields);
        for(var f in this.record.fields.mydeliver.subfields[index]){
          if(typeof myaddress.fields[f]!="undefined"){
            //if(["sei","mei","sei_kana","mei_kana"].indexOf(f)!==-1)continue;
            this.$set(this.record.fields.mydeliver.subfields[index][f],"value",myaddress.fields[f].value);
          }
        }
        if(!silent){
          this.error_title= "住所情報を読み込みました。";
          console.log(myaddress.fields);
          this.error_info = "保存済みの「"+myaddress.fields.title.value+"」の住所情報を読み込ました。";
          this.visible_error = true;
        }
       console.log("this.record.fields.mydeliver.subfields",this.record.fields.mydeliver.subfields)
       
          this.$set(this.record.fields.mydeliver.subfields[index],"addressmode",{"value":"update"});
      }

      //住所の読み込みによって、都道府県が変更された場合は、合計金額を計算しなおす
      if(prev_state!=this.record.fields.mydeliver.subfields[index].state.value){
        this.update_deliver();
      }


    },
		get_option_value(index){
      if(this.values[index].options.value){
      return JSON.parse(this.values[index].options.value);
      }else{
        return [];
      }
		},
    zipcode_get:function(i){
    
      this.fetch("zipcode",{ zipcode: this.record.fields.mydeliver.subfields[i-1].zip.value }).then((result) =>{
        
        if(result){
        this.$set(this.record.fields.mydeliver.subfields[i-1].state,"value",result.data[0].ja.prefecture);
        this.$set(this.record.fields.mydeliver.subfields[i-1].city,"value",result.data[0].ja.address1);
        this.$set(this.record.fields.mydeliver.subfields[i-1].street,"value",result.data[0].ja.address2);
        }
      });
      
    },
    submit: function () {
      
      this.scrollToTop();
      this.fullloading=true;
      var record = this.getSession("record");
      //ログイン済みの場合はトークン、非会員の場合はuserを「-1」にする。
      var token = this.get_localStorage("token");
      if (!token) record.user = "-1";
      this.fetch("order_submit", {
        token: token,
        record: record,
        mode: "check",
      }).then((data) => {
      this.fullloading=false;
        console.log(data);
        if(typeof data.record!="undefined")this.$set(this.record,"fields",Object.assign(this.record.fields, data.record.fields));
        this.errors_global = {};
        if (typeof data.error != "undefined") {
          this.errors_global=data.error;
          console.log("data.error",data.error);
           this.error_title= "以下をご確認ください";
          this.error_info = "不備の項目があります。<br>";
            for(let u=1;u<=this.values.length;u++){
          //var filtered_ids = ["sei","mei","sei_kana","mei_kana","zip","state","city","street","building","other","tel","email","email_c","userpass","userpass_c","policy"];
          for (let i = 0; i < data.error.length; i++) {
              console.log(u,"/^field_mydeliver-([a-z0-9_]+)-("+u+")$/")
              let reg=new RegExp("^field_mydeliver-([a-z0-9_]+)-("+u+")$")
              let optionID=data.error[i].id.match(reg);
              console.log(data.error[i].id,optionID)
              if (optionID !== null) console.log(optionID);
              if (optionID !== null) {
                //if(this.unit <= optionID[1])continue;
                this.error_info+="・ "+data.error[i].msg+"<br>";
                this.visible_error = true;
              }
            }
          }
        }
        if (!this.visible_error) {
          if(this.$route.query.rd=='back'){
            this.$router.back();
          }else{
            if(this.ID){
              this.$router.push('/item/'+this.label+'/payment/update/'+this.ID);
            }else{
              this.$router.push('/item/'+this.label+'/payment');
            }
          }
        }
      });
    },
    back:function(){
      this.scrollToTop();
          if(this.ID){
          this.$router.push('/item/'+this.label+'/sender/update/'+this.ID);
          }else{
          this.$router.push('/item/'+this.label+'/sender');
          }
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  color: #000;
}
.nesry {
    font-size: 70%;
    float: right;
    padding-top: 2px;
    padding-right: 7px;
    padding-bottom: 2px;
    padding-left: 7px;
    color: #F51212;
    margin-right: 5px;
    border: 1px solid #F51212;
}
.formListTd {
	background-color: #D8D8D8 !important;
	padding: 5px !important;
}
.formListTdw {
	background-color: #ffffff !important;
	padding: 5px !important;
}
.formListcap {
	background-color: #FFFCE1 !important;
	padding: 15px !important;
	text-align:center;
}

table.formBox {
	border-collapse: separate;
	border-spacing: 1px 1px;
	background-color: #ACC1AC;
    margin-bottom: 25px;
    text-align: left;
}
table.formBox  td{
	background:#dbdbdb;
	border: #ffffff 1px solid;
}
#form_status .btn {
	font-size: 110%;
	margin-top: 15px;
	padding-right: 2em;
	padding-left: 2em;
	padding-top: 5px;
	padding-bottom: 5px;
}

.formBox .formListTh5 {
	text-align:center;
	color: #FFF;
	background-color: #73A563;
	padding: 5px;
}

.formBox .formListTh {
	text-align: center;
	color: #FFF;
	background-color: #F20C00;
	padding: 5px;
}
.formBox .graybacktd {
	background-color: #70837D;
}
.formBox .formListTh2 {
	color: #FFFFFF;
	background-color: #AFAFAF;
	padding: 5px;
}
.formBox .formListTh3 {
	color: #313131;
	background-color: #DEE4DC;
	padding: 5px;
	width: 22%;
}

.formBox .formListTh4 {
	text-align: left;
	font-weight:bold;
	color: #FFF;
	background-color: #46663C;
	padding: 5px;
}
.delivers{
  margin-bottom: 1em;
  .description{
    background: rgb(238, 238, 238);
    padding: .8em;
    border-radius: .4em;
  }
  &:last-child{
    margin-bottom: 0;
  }
}
.deliverinfo{
  .info{
    margin-top: .6em;
    background: #EEEEEE;
    padding: 1.5em;
    line-height: 1.7;
    color: #D11E1E;
  }
  .img{
    p{
      margin-bottom: 1em;
    }
  text-align: center;
  width: 100%;
  img{
    max-width: 80%;
  }
  }
}
.arrive_selector{
  padding: .8em .3em;
}
@media all and (-ms-high-contrast: none) {
  /* ここに書く */
.deliverinfo{
  .img{
    img{
    width:80%;
    }
  }
}
}
</style>